import(/* webpackMode: "eager", webpackExports: ["BannerContent"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/BannerContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardAssetAllocation"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardAssetAllocation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardMarketValueHistory"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardMarketValueHistory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardMyNetWorth"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardMyNetWorth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardMyPortfolio"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardMyPortfolio.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardMyTeam"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardMyTeam.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardMyUpdates"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardMyUpdates.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPlanning"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardPlanning.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPortfolioPerformance"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardPortfolioPerfomance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardQuickActions"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardQuickActions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardRecentActivity"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardRecentActivity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardRecentDocuments"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardRecentDocuments.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardWatchlist"] */ "/working_dir/uat/application/app/_ui/components/Dashboard/CardWatchlist.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageRedirects"] */ "/working_dir/uat/application/app/(secured)/page-redirects.tsx");
